<template>
  <ValidationObserver ref="saveDataValidate">
    <form @submit.prevent="saveData">
      <b-card title="Create Aktivitas">
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Thumbnail</label>
          <div class="col">
            <div v-if="formPayload.thumbnail" class="mt-1 relative">
              <img :src="image_thumbnail" height="300" width="650" />
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle ml-2"
                @click="cancelThumbnail()"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
            <div v-else>
              <validation-provider
                #default="{ errors }"
                name="banner"
                rules="required"
              >
                <b-form-file
                  required
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="onFileSelectedThumbnail"
                  accept="image/png, image/gif, image/jpeg"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <span class="text-danger" v-if="!errors.length"
                  ><small>* file harus berdimensi 3000 x 1000</small></span
                >
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Title</label>
          <div class="col">
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required"
            >
            <b-form-input
              v-model="formPayload.title"
              placeholder="Title"
              required
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Description:</label>
          <div class="col">
            <vue-editor ref="blogtext" v-model="editorContent" v-if="editorContent" :editor-toolbar="customToolbar"></vue-editor>
            <small v-if="validations.blog_text">{{ validations.blog_text[0] }}</small>
          </div>
        </div>
        <!-- <div class="form-group form-row">
          <label class="col-4 col-form-label">Set Banner</label>
          <div class="col">
            <b-form-checkbox
              v-model="formPayload.is_banner"
              name="is-vertical-menu-collapsed"
              class="mr-0"
              switch
              inline
            />
          </div>
        </div> -->
        <div v-if="formPayload.is_banner">
          <div class="form-group form-row">
            <label class="col-4 col-form-label">Link</label>
            <div class="col">
              <b-form-input
                v-model="formPayload.link_url"
                placeholder="Link"
                required
              />
            </div>
          </div>
          <div class="form-group form-row">
            <label class="col-4 col-form-label">Banner</label>
            <div class="col">
              <div v-if="formPayload.image_banner" class="mt-1 relative">
                <img :src="bannerImage" height="200" width="200" />
                <b-button
                  variant="gradient-danger"
                  class="btn-icon rounded-circle ml-2"
                  @click="cancelBannerImage()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
              <div v-else>
                <b-form-file
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="onFileSelectedBannerImage"
                  accept="image/png, image/gif, image/jpeg"
                />
                <span class="text-danger"
                  ><small>* file harus berdimensi 2250 x 2250</small></span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Start Date:</label>
          <div class="col">
            <validation-provider
              #default="{ errors }"
              name="startdate"
              rules="required"
            >
            <flat-pickr
              v-model="formPayload.start_date"
              class="form-control"
              placeholder="Start Date"
              :config="{
                locale: {
                  rangeSeparator: ' - ',
                },
              }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">End Date:</label>
          <div class="col">
            <validation-provider
              #default="{ errors }"
              name="enddate"
              rules="required"
            >
            <flat-pickr
              v-model="formPayload.end_date"
              class="form-control"
              placeholder="End Date"
              :config="{
                locale: {
                  rangeSeparator: ' - ',
                },
              }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
      </b-card>
      <b-col cols="12" class="p-0 mt-2">
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Save
        </button>
        &nbsp; &nbsp;
        <a
          @click="handleBackButton()"
          variant="secondary"
          class="btn waves-effect waves-float waves-light btn-secondary"
        >
          Cancel
        </a>
      </b-col>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormFile,
  BButton,
  BSpinner,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { VueEditor } from "vue2-editor";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"],
  },
});
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormFile,
    BFormInput,
    BFormTextarea,
    VueEditor,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  data() {
    return {
      editorContent : null,
      newData: "",
      required,
      isLoading: false,
      formPayload: {
        thumbnail: "",
        title: "",
        blog_text: "",
        start_date: "",
        end_date: "",
        is_banner: false,
        image_banner: "",
        link_url: "",
        form_id: "",
      },
      image_thumbnail: "",
      bannerImage: "",
      fileVendorImage: [],
      filesThumbnail: [],
      customToolbar: [
				[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
				["bold", "italic"],
				["image", "video"],
				["link"],
				[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
			],
      snowOption: {
        theme: "snow",
      },
      validations: {},
    };
  },
  setup(props) {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.loadData();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    cancelThumbnail() {
      this.formPayload.thumbnail = null;
    },
    onFileSelectedThumbnail(event) {
      this.formPayload.thumbnail = event.target.files[0];
      this.showfileThumbnailImage(event.target.files[0]);
    },
    showfileThumbnailImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image_thumbnail = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    cancelBannerImage() {
      this.formPayload.image_banner = null;
    },
    onFileSelectedBannerImage(event) {
      this.formPayload.image_banner = event.target.files[0];
      this.showfileBannerImage(event.target.files[0]);
    },
    showfileBannerImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.bannerImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    preparePayload() {
      this.formPayload.blog_text = this.editorContent;
      this.formPayload.is_banner
        ? (this.formPayload.is_banner = 1)
        : (this.formPayload.is_banner = 0);
      const form = new FormData();
      for (const key in this.formPayload) {
        form.append(key, this.formPayload[key]);
      }
      return form;
    },
    saveData() {
      this.$refs.saveDataValidate.validate().then((success) => {
        if (success) {
          const slugs = this.$route.params.slug;
          var form = this.preparePayload();
          this.isLoading = true;
          form.append("_method", "PATCH");
          this.$http
            .post(`/admin/blog/${slugs}`, form, {
              headers: { "content-type": "multipart/form-data" },
            })
            .then((response) => {
              successNotification(this, "Success", "Aktivitas edited!");
              this.$router.push({ name: "aktivitas" });
              this.isLoading = false;
            })
            .catch((error) => {
              this.validations = error.response.data.meta.validations;
              this.isLoading = false;
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
              if (error.response.data.meta.validations) {
                this.validations = error.response.data.meta.validations;
                errorNotification(
                  this,
                  "Nama",
                  this.validations.name.join(" ")
                );
              }
              this.isLoading = false;
            });
        }
      });
    },
    loadData() {
      const params = this.$route.params.slug;
      this.$http
        .get("/admin/blog/" + params)
        .then((response) => {
          this.formPayload = response.data.data;
          this.image_thumbnail = this.formPayload.thumbnail;
          this.bannerImage = this.formPayload.image_banner;
          this.formPayload.is_banner == 1
            ? (this.formPayload.is_banner = true)
            : (this.formPayload.is_banner = false);
          
          this.editorContent = this.formPayload.blog_text;
        })
        .then(() => {})
        .catch((error) => {
        });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
@import "@core/scss/vue/libs/vue-select.scss";
</style>
